<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/dd-logo.svg')"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-xl font-weight-semibold text--primary mb-2 text-center">
            {{ $store.state.appName }}
          </p>
          <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              outlined
              label="Username"
              placeholder="john@example.com"
              class="mb-3"
              :error-messages="formErrorFields.usernameErrorMessage[0]"
              @keydown="formError().remove('username', formErrorFields)"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="formErrorFields.passwordErrorMessage[0]"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @keydown="formError().remove('password', formErrorFields)"
              @keypress.enter="signinWasClicked"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="/auth/forgot-password"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading"
              @click="signinWasClicked"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          &nbsp;
        </v-card-actions>
      </v-card>
    </div>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      @close="message=''"
    >
    </snackbar>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import signin from '@/composables/login/signin'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '@/utils'

export default {
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'LOGIN',
    }
  },
  setup() {
    const { router } = useRouter()
    const message = ref('')
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const formErrorFields = ref({
      usernameErrorMessage: '',
      passwordErrorMessage: '',
    })

    const loading = ref(false)

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    const { go, errorData } = signin()

    const signinWasClicked = async () => {
      loading.value = true
      await go(username, password, formErrorFields, router)
      if (errorData.value.status === 403) {
        await localStorage.setItem('userId', errorData.value.data)
        router.replace('/auth/otp')
      } else {
        message.value = errorData.value.message !== undefined ? errorData.value.message : ''
        loading.value = false
      }
    }

    return {
      isPasswordVisible,
      username,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      signinWasClicked,
      formErrorFields,
      formError,
      message,
      loading,
    }
  },
  components: {
    snackbar,
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
